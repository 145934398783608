.step-app > .step-steps {
  margin: 0;
  padding: 0;
  display: flex;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.step-app > .step-steps > li {
  list-style: none;
  flex: 1;
  cursor: pointer;
  display: block;
  padding: 10px;
  color: #333;
  background-color: #e5e5e5;
  text-decoration: none;
  border-right: 1px solid #fff;
}
.step-app > .step-steps > li:hover {
  background-color: #ddd;
}
.step-app > .step-steps > li:last-child a {
  border: none;
}
.step-app > .step-steps > li.active {
  background-color: #32c5d2;
  color: #fff;
}
.step-app > .step-steps > li.error {
  background-color: #e7505a;
  color: #fff;
}
.step-app > .step-steps > li.done {
  background-color: #3cb371;
  color: #fff;
}
.step-app > .step-steps > li > .number {
  background: #fff;
  padding: 0 8px;
  display: inline-block;
  text-align: center;
  margin-right: 15px;
  border-radius: 3px;
  color: #333;
}
.step-app > .step-content {
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-top: 0;
}
.step-app > .step-content > .step-tab-panel {
  display: none;
}
.step-app > .step-content > .step-tab-panel.active {
  display: block;
}
.step-app > .step-footer {
  margin-top: 15px;
  margin-bottom: 15px;
}
.step-app > .step-footer > .step-btn {
  padding: 4px 16px;
  color: #333;
  text-decoration: none;
  background: #e5e5e5;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
